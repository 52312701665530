import "./mobile-menu.scss";

import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import creditCard from "./img/credit-card.svg";
import MainNavCitySelect from "@components/layout/header/main-nav-city-select";

import Link from "gatsby-link";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel
} from "@components/accordion";
import Socials from "@components/layout/footer/socials";

const MobileMenu = ({ menuLinks, isOpen, onClick, height }) => {
	return (
		<div
			className={cn("mobile-menu hidden-md", { "mobile-menu--open": isOpen })}
			style={{
				paddingTop: `${height}px`
			}}
		>
			<div className="grid-container mobile-menu__bottom-links">
				<div className="row">
					<div className="col-xs-12 col-sm-6">
						<Link
							onClick={onClick}
							className="mobile-menu__bottom-links__link"
							to={"/payment"}
						>
							<img alt={"Пополнить карту"} src={creditCard} />
							<span>Пополнить карту</span>
						</Link>
					</div>
				</div>
			</div>
			<div className="grid-container">
				<nav className="mobile-menu__nav">
					<Accordion allowZeroExpanded>
						{menuLinks.map((menuLink, index) => {
							const { subsections, name } = menuLink;
							return (
								<AccordionItem key={index}>
									<AccordionItemHeading>{name}</AccordionItemHeading>
									<AccordionItemPanel>
										{subsections.map((subsection, index) => {
											const { name, to, link } = subsection;
											let view = null;
											if (to || (!to && !link)) {
												view = (
													<Link
														onClick={onClick}
														className="accordion__link"
														to={to || "/not-found"}
														key={index}
													>
														{name}
													</Link>
												);
											} else if (link) {
												view = (
													<a
														onClick={onClick}
														className="accordion__link"
														href={link}
														target="_blank"
														key={index}
														rel="noopener noreferrer"
													>
														{name}
													</a>
												);
											}
											return view;
										})}
									</AccordionItemPanel>
								</AccordionItem>
							);
						})}
					</Accordion>
				</nav>
			</div>
			<MainNavCitySelect
				blockDocument={false}
				className="main-nav-city-select_mobile hidden-sm"
			/>
			<Socials className="socials_mobile" />
		</div>
	);
};

MobileMenu.propTypes = {
	isOpen: PropTypes.bool
};

MobileMenu.defaultProps = {
	isOpen: false
};

export default MobileMenu;
