import "./footer-section.scss";
import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";

const FooterSection = ({ data }) => (
	<div className="footer-section">
		{data && (
			<>
				<h6 className="footer-section__header">{data.name}</h6>
				<ul className="footer-section__list">
					{data.subsections.map((item, index) => {
						let view = null;
						if (item.to || (!item.to && !item.link)) {
							view = (
								<Link
									key={index}
									to={item.to || "/"}
									className="footer-section__link"
								>
									<li className="footer-section__list-item">{item.name}</li>
								</Link>
							);
						} else if (item.link) {
							view = (
								<a
									key={index}
									href={item.link}
									target="_blank"
									className="footer-section__link"
									rel="noopener noreferrer"
								>
									<li className="footer-section__list-item">{item.name}</li>
								</a>
							);
						}
						return view;
					})}
				</ul>
			</>
		)}
	</div>
);

FooterSection.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		subsections: PropTypes.array
	}).isRequired
};

export default FooterSection;
