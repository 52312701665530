import "./footer.scss";

import React from "react";
import Link from "gatsby-link";

import FooterSection from "./footer-section";
import FooterExtra from "./footer-extra";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel
} from "@components/accordion";

const Footer = ({ menuLinks }) => (
	<footer className="full-width footer">
		<div className="grid-container">
			<nav className="footer__mobile-nav hidden-md">
				<Accordion allowZeroExpanded className="accordion_tone_dark">
					{menuLinks.map((menuLink, index) => {
						const { subsections, name } = menuLink;
						return (
							<AccordionItem key={index}>
								<AccordionItemHeading>{name}</AccordionItemHeading>
								<AccordionItemPanel>
									{subsections.map((subsection, index) => {
										const { name, to, link } = subsection;
										let view = null;
										if (to) {
											view = (
												<Link
													className="accordion__link"
													to={to || "/not-found"}
													key={index}
												>
													{name}
												</Link>
											);
										} else if (link) {
											view = (
												<a
													className="accordion__link"
													href={link}
													target="_blank"
													key={index}
													rel="noopener noreferrer"
												>
													{name}
												</a>
											);
										}
										return view;
									})}
								</AccordionItemPanel>
							</AccordionItem>
						);
					})}
				</Accordion>
			</nav>
			<nav className="row footer__nav hidden-xs hidden-sm">
				<div className="col-md-3">
					<FooterSection data={menuLinks.find(link => link.code === "kinds")} />
					<FooterSection
						data={menuLinks.find(link => link.code === "services")}
					/>
				</div>
				<div className="col-md-3">
					<FooterSection
						data={menuLinks.find(link => link.code === "aboutCompany")}
					/>
					<FooterSection
						data={menuLinks.find(link => link.code === "where-to-buy")}
					/>
				</div>
				<div className="col-md-3">
					<FooterSection
						data={menuLinks.find(link => link.code === "where-to-refill")}
					/>
				</div>
				<div className="col-md-3">
					<FooterSection
						data={menuLinks.find(link => link.code === "contacts")}
					/>
					<FooterSection
						data={menuLinks.find(link => link.code === "information")}
					/>
					<FooterSection
						data={menuLinks.find(link => link.code === "question-answer")}
					/>
				</div>
			</nav>
		</div>
		<FooterExtra />
	</footer>
);

export default Footer;
