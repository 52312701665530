import "./socials.scss";
import React from "react";
import vk from "./vk.svg";
import youtube from "./youtube.svg";
import cn from "classnames";

const Socials = props => {
	const { className } = props;
	return (
		<section className={cn("socials", className)}>
			<a
				className="socials__item"
				target="_blank"
				rel="noopener noreferrer"
				href="https://vk.com/oao_socialcard"
			>
				<img src={vk} alt="VK" />
			</a>
			<a
				className="socials__item"
				target="_blank"
				rel="noopener noreferrer"
				href="https://www.youtube.com/channel/UCCV5L5hx5MpyhlB7v0m15MA"
			>
				<img src={youtube} alt="Youtube" />
			</a>
		</section>
	);
};

export default Socials;
