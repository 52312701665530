import React from "react";
import {
	AccordionItemHeading,
	AccordionItemButton
} from "react-accessible-accordion";
import "./accordion.scss";

const CustomAccordionItemHeading = props => {
	return (
		<AccordionItemHeading className="accordion__heading">
			<AccordionItemButton className="accordion__button">
				{props.children}
			</AccordionItemButton>
		</AccordionItemHeading>
	);
};

export default CustomAccordionItemHeading;
