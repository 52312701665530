import React from "react";

import logo from "./img/logo-monochrome.svg";
import "./footer-extra.scss";

import Socials from "../socials";

const currentYear = new Date().getFullYear();
const COPYRIGHT = `АО «Социальная карта» ${currentYear}`;

const FooterExtra = () => {
	return (
		<div className="full-width footer-extra">
			<div className="grid-container">
				<div className="row">
					<div className="footer-extra__mobile hidden-md">
						<div className="footer-extra__mobile__tk-logo col-xs-12 col-sm-6">
							<img src={logo} alt="Логотип транспортной карты" />
						</div>
						<div className="footer-extra__mobile__social col-xs-12 col-sm-6">
							<Socials />
						</div>
						<div className="col-xs-12 col-sm-12">
							<p className="footer-extra__mobile__copyright">{COPYRIGHT}</p>
						</div>
					</div>
					<div className="footer-extra__desktop hidden-xs hidden-sm full-width">
						<div className="footer-extra__desktop__tk-logo col-md-3">
							<img src={logo} alt="Логотип транспортной карты" />
						</div>
						<div className="col-md-6">
							<p className="footer-extra__desktop__copyright">{COPYRIGHT}</p>
						</div>
						<div className="footer-extra__desktop__social col-md-3">
							<Socials />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterExtra;
