import axios from "axios";
import { cmsServer, backendServer } from "@constants";

export const backend = axios.create({
	baseURL: backendServer
});

export const cms = axios.create({
	baseURL: cmsServer
});
