import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./general-modal.scss";
import Modal from "react-modal";

/** Модальное окно */
const GeneralModal = props => {
	const { isOpen, closeModal, children, contentLabel, blockDocument } = props;

	useEffect(() => {
		Modal.setAppElement("body");
		if (blockDocument) {
			if (isOpen) {
				document.body.style.overflow = "hidden";
			} else {
				document.body.style.overflow = "";
			}
		}
	}, [isOpen, blockDocument]);

	const close = () => {
		closeModal();
	};

	const onAfterOpen = () => {
		if (document.querySelector(".ReactModal__Overlay")) {
			document.querySelector(".ReactModal__Overlay").scrollTop = 0;
		}
	};

	return (
		<Modal
			onAfterOpen={onAfterOpen}
			isOpen={isOpen}
			contentLabel={contentLabel}
			onRequestClose={close}
			className="general-modal col-xs-12 col-sm-8 col-md-6"
			overlayClassName="overlay-general-modal"
		>
			<div className="row">
				<button className="general-modal__button-close" onClick={closeModal}>
					close
				</button>
				{children}
			</div>
		</Modal>
	);
};

GeneralModal.propTypes = {
	modalIsOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	children: PropTypes.node,
	contentLabel: PropTypes.string,
	blockDocument: PropTypes.bool
};

GeneralModal.defaultProps = {
	blockDocument: true
};

export default GeneralModal;
