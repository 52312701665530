// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-awards-js": () => import("./../../../src/pages/about/awards.js" /* webpackChunkName: "component---src-pages-about-awards-js" */),
  "component---src-pages-about-became-partner-js": () => import("./../../../src/pages/about/became-partner.js" /* webpackChunkName: "component---src-pages-about-became-partner-js" */),
  "component---src-pages-about-news-js": () => import("./../../../src/pages/about/news.js" /* webpackChunkName: "component---src-pages-about-news-js" */),
  "component---src-pages-about-open-info-js": () => import("./../../../src/pages/about/open-info.js" /* webpackChunkName: "component---src-pages-about-open-info-js" */),
  "component---src-pages-cards-js": () => import("./../../../src/pages/cards.js" /* webpackChunkName: "component---src-pages-cards-js" */),
  "component---src-pages-contacts-requisites-js": () => import("./../../../src/pages/contacts/requisites.js" /* webpackChunkName: "component---src-pages-contacts-requisites-js" */),
  "component---src-pages-in-progress-js": () => import("./../../../src/pages/in-progress.js" /* webpackChunkName: "component---src-pages-in-progress-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-faq-js": () => import("./../../../src/pages/information/faq.js" /* webpackChunkName: "component---src-pages-information-faq-js" */),
  "component---src-pages-information-for-legal-entities-js": () => import("./../../../src/pages/information/for-legal-entities.js" /* webpackChunkName: "component---src-pages-information-for-legal-entities-js" */),
  "component---src-pages-information-question-js": () => import("./../../../src/pages/information/question.js" /* webpackChunkName: "component---src-pages-information-question-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-services-check-balance-js": () => import("./../../../src/pages/services/check-balance.js" /* webpackChunkName: "component---src-pages-services-check-balance-js" */),
  "component---src-pages-services-detail-js": () => import("./../../../src/pages/services/detail.js" /* webpackChunkName: "component---src-pages-services-detail-js" */),
  "component---src-pages-services-stop-covid-js": () => import("./../../../src/pages/services/stop-covid.js" /* webpackChunkName: "component---src-pages-services-stop-covid-js" */),
  "component---src-pages-services-tariffs-js": () => import("./../../../src/pages/services/tariffs.js" /* webpackChunkName: "component---src-pages-services-tariffs-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../../../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */),
  "component---src-pages-where-to-refill-js": () => import("./../../../src/pages/where-to-refill.js" /* webpackChunkName: "component---src-pages-where-to-refill-js" */),
  "component---src-templates-simple-page-simple-page-template-jsx": () => import("./../../../src/templates/simple-page/simple-page-template.jsx" /* webpackChunkName: "component---src-templates-simple-page-simple-page-template-jsx" */)
}

