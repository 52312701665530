import React from "react";
import { AccordionItemPanel } from "react-accessible-accordion";
import "./accordion.scss";

const CustomAccordionItemPanel = props => {
	return (
		<AccordionItemPanel className="accordion__panel">
			{props.children}
		</AccordionItemPanel>
	);
};

export default CustomAccordionItemPanel;
