const unitellerLogin = "https://social-card.uniteller.ru/auth/login/";
const checkBalance = "http://81.23.146.8:81";
const cmsServer =
	process.env.NODE_ENV === "production"
		? "https://api.social-card.ru"
		: "http://localhost:1337";
const backendServer =
	process.env.NODE_ENV === "production"
		? "https://social-card.ru"
		: "http://localhost:3000";
const paymentModule =
	process.env.NODE_ENV === "production"
		? "https://cdn.social-card.ru/payment/main.js?kpf9b6af"
		: "http://localhost:3001/main.js";

module.exports = {
	unitellerLogin,
	checkBalance,
	cmsServer,
	backendServer,
	paymentModule
};
