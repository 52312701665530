import React from "react";
import list from "./list";
import NavItem from "./nav-item";
import cn from "classnames";
import "./nav-items.scss";

const NavItems = ({ location, menuLinks, className }) => {
	let isActive;
	return (
		<nav className={cn("nav-items", className)}>
			{list.map((item, index) => {
				if (location && location.href) {
					isActive = location.href.includes(item.link);
				}
				return (
					<NavItem
						key={index}
						menuLinks={menuLinks}
						item={item}
						isActive={isActive}
						location={location}
					/>
				);
			})}
		</nav>
	);
};

export default NavItems;
