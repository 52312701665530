export const transformMenuToTitleList = (menu = []) => {
	const obj = {};
	menu.forEach(item => {
		item.subsections.forEach(section => {
			const { to, name, title } = section;
			if (title) {
				obj[to] = title;
			} else {
				obj[to] = name;
			}
		});
	});
	return obj;
};
