import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@components/button";
import GeneralModal from "@components/general-modal";
import cn from "classnames";
import { backend as axios } from "@core/axios";

import "./main-nav-city-select.scss";

const MainNavCitySelectComponent = props => {
	const { className, setCity, city, cities, blockDocument } = props;

	const [modalIsOpen, setModalIsOpen] = useState(false);

	/** Получаем город клиента с сервера */
	const getLocation = useCallback(async () => {
		if (cities) {
			const location = await axios("/api/ip");
			if (location.data && location.data.city) {
				let currentCity = cities.find(
					({ name }) => name === location.data.city
				);
				currentCity = currentCity ? currentCity.name : "Казань";
				// Если город в списке не найден, то по умолчанию устанавливаем город Казань
				window.localStorage.setItem("city", currentCity);
				setCity(currentCity);
			} else {
				window.localStorage.setItem("city", "Казань");
				setCity("Казань");
			}
		}
	}, [cities, setCity]);

	useEffect(() => {
		let cityFormStorage = window.localStorage.getItem("city");
		if (cityFormStorage) {
			setCity(cityFormStorage);
		} else {
			getLocation();
		}
	}, [cities, getLocation, setCity]);

	/** Выбрать город в ручную */
	const changeCity = useCallback(
		city => () => {
			window.localStorage.setItem("city", city);
			setCity(city);
			setModalIsOpen(false);
		},
		[setCity]
	);

	/** Автоопределение города */
	const autoChangeCity = useCallback(() => {
		getLocation();
		setModalIsOpen(false);
	}, [getLocation]);

	/** Открыть модальное окно */
	const openModal = useCallback(() => {
		setModalIsOpen(true);
	}, []);

	/** Закрыть модальное окно */
	const closeModal = useCallback(() => {
		setModalIsOpen(false);
	}, []);

	return city ? (
		<section className={cn("main-nav-city-select", className)}>
			<Button
				title={city}
				onClick={openModal}
				className="main-nav-city-select__button_geolocation"
			/>
			<GeneralModal
				isOpen={modalIsOpen}
				contentLabel="Modal"
				closeModal={closeModal}
				blockDocument={blockDocument}
			>
				<div className="select-city-modal">
					<div className="select-city-modal__header">
						<h4>Выберите ваш город</h4>
					</div>
					<hr />
					<div className="select-city-modal__content">
						{cities && (
							<ul className="select-city-modal__content__select-cities">
								{cities.map(({ name, id }) => (
									<li
										key={id}
										onClick={changeCity(name)}
										className={cn({ [`active`]: name === city })}
									>
										<span>{name}</span>
									</li>
								))}
							</ul>
						)}
					</div>
					<hr className="hidden-md" />
					<button
						className="select-city-modal__content__autodetect-city"
						onClick={autoChangeCity}
					>
						Определить город автоматически
					</button>
				</div>
			</GeneralModal>
		</section>
	) : null;
};

MainNavCitySelectComponent.propTypes = {
	className: PropTypes.string,
	setCity: PropTypes.func,
	city: PropTypes.string,
	blockDocument: PropTypes.bool
};

const mapDispatchToProps = dispatch => {
	return {
		setCity: city => {
			dispatch({
				type: "SET_DEFAULT_CITY",
				payload: {
					city
				}
			});
		}
	};
};

const mapStateToProps = ({ city, staticData: { cities } = {} }) => {
	return { city, cities };
};

const MainNavCitySelect = connect(
	mapStateToProps,
	mapDispatchToProps
)(MainNavCitySelectComponent);

export default MainNavCitySelect;
