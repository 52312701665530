import "@styles/base.scss";
import "./layout.scss";
import React, { useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Breadcrumbs from "./breadcrumbs";
import { Header } from "./header";
import { Footer } from "./footer";
import { connect } from "react-redux";
import { transformMenuToTitleList } from "./utils";

const LayoutComponent = props => {
	const {
		children,
		location,
		city,
		pageContext: { breadcrumb: { crumbs = [] } = {} } = {},
		setGraphqlStaticData
	} = props;

	const data = useStaticQuery(graphql`
		query {
			siteMetadata: allStrapiSettings(
				filter: { code: { eq: "site_meta_data" } }
			) {
				nodes {
					code
					value {
						description
						siteUrl
						title
						menuLinks {
							code
							name
							subsections {
								link
								name
								to
							}
						}
					}
				}
			}
			companies: allStrapiCompanies {
				nodes {
					id
					name
					points {
						refill
						city
						sale
					}
					strapiId
					icon {
						publicURL
					}
				}
			}
			cities: allStrapiCities(sort: { fields: name }) {
				nodes {
					geo_position {
						latitude
						longitude
					}
					id
					name
					strapiId
				}
			}
			cards: allStrapiCards(sort: { fields: order, order: ASC }) {
				nodes {
					id
					code
					description
					strapiId
					title
					cost
					image {
						publicURL
					}
					tariffs {
						name
						id
						bus
						trolleybus
						tram
						cities
						tariff_field {
							id
							label
							value
						}
					}
					advantage_of_using {
						item
						id
					}
					how_to_get {
						id
						item
					}
					other_cards
				}
			}
			faqs: allStrapiFaqs(sort: { order: ASC, fields: order }) {
				nodes {
					answer
					question
					id
					order
					documents {
						id
						name
						file {
							name
							size
							url
						}
					}
				}
			}
		}
	`);

	const {
		siteMetadata,
		companies = {},
		cities = {},
		cards = {},
		faqs = {}
	} = data;

	const site = siteMetadata.nodes[0];
	const { value: { menuLinks, title: defaultTitle, description } = {} } = site;

	useEffect(() => {
		setGraphqlStaticData({
			companies: companies.nodes,
			cities: cities.nodes,
			cards: cards.nodes,
			faqs: faqs.nodes
		});
	}, [faqs, companies, cities, cards, setGraphqlStaticData]);

	/** Атогенерация меню для точек продаж и пополнения */
	const menu = useMemo(() => {
		if (city && menuLinks) {
			console.log("generate menu");
			const companiesList = [];
			const companiesIcons = {};
			let result = {
				sale: [],
				refill: []
			};
			const getIdCurrentCity = cities.nodes.find(item => item.name === city);
			const { strapiId: idCurrentCity } = getIdCurrentCity;
			companies.nodes.length &&
				companies.nodes.forEach(company => {
					const { name, icon, strapiId } = company;
					// Сортировка по городу
					const pointsSortByCity = company.points.filter(point => {
						return point.city === idCurrentCity;
					});
					let findSale = false;
					let findRefill = false;
					companiesIcons[name] = icon && icon.publicURL;
					for (let value of pointsSortByCity) {
						const { sale, refill } = value;
						if (sale && !findSale) {
							findSale = true;
							result.sale.push({
								name,
								to: `/where-to-buy?company=${encodeURI(name)}`,
								title: `Где приобрести | ${name}`
							});
							companiesList[`sale_${strapiId}`] = {
								url: icon && icon.publicURL,
								name: name,
								type: "sale",
								id: `sale_${strapiId}`,
								originalId: strapiId
							};
						}
						if (refill && !findRefill) {
							findRefill = true;
							result.refill.push({
								name,
								to: `/where-to-refill?company=${encodeURI(name)}`,
								title: `Где пополнить | ${name}`
							});
							companiesList[`refill_${strapiId}`] = {
								url: icon && icon.publicURL,
								name: name,
								type: "refill",
								id: `refill_${strapiId}`,
								originalId: strapiId
							};
						}
						if (findRefill && findSale) {
							break;
						}
					}
				});
			menuLinks.find(({ code }) => code === "where-to-refill").subsections =
				result.refill;
			menuLinks.find(({ code }) => code === "where-to-buy").subsections =
				result.sale;
			setGraphqlStaticData({
				companiesForMap: { list: companiesList, icons: companiesIcons }
			});
		}
		if (cards) {
			const cardsMenu = menuLinks.find(({ code }) => code === "kinds");
			cardsMenu.subsections = cards.nodes
				.filter(({ other_cards }) => !other_cards)
				.map(card => {
					return {
						name: card.title,
						to: `/cards?type=${card.code}`
					};
				});
			if ((cards.nodes.filter(({ other_cards }) => other_cards) || []).length) {
				cardsMenu.subsections = [
					...cardsMenu.subsections,
					{
						name: "Прочие",
						to: `/cards?type=other`
					}
				];
			}
		}
		return menuLinks;
	}, [city, menuLinks, companies, cards, cities, setGraphqlStaticData]);

	/** Определение заголовка страницы */
	const title = useMemo(() => {
		if (location && menu) {
			const currentLocationPath = `${location.pathname}${location.search}`;
			switch (currentLocationPath) {
				case "/where-to-refill/": {
					return "Где пополнить";
				}
				case "/where-to-buy/": {
					return "Где приобрести";
				}
				case "/instruction/": {
					return "Как пользоваться";
				}
				default: {
					return transformMenuToTitleList(menu)[currentLocationPath];
				}
			}
		}
	}, [menu, location]);

	return (
		<div className="app-wrapper">
			<Helmet
				title={title || defaultTitle}
				meta={[
					{ name: "description", content: description },
					{ name: "keywords", content: "Социальная карта РТ" }
				]}
			>
				<html lang="ru" />
			</Helmet>
			<Header menuLinks={menu || []} location={location} />
			{location &&
				location.pathname !== "/" &&
				location.pathname !== "/cards" &&
				location.pathname !== "/cards/" && <Breadcrumbs crumbs={crumbs} />}
			<section className="main-content">{children}</section>
			<Footer menuLinks={menu || []} />
		</div>
	);
};

const mapStateToProps = ({ city }) => {
	return { city };
};

const mapDispatchToProps = dispatch => {
	return {
		setGraphqlStaticData: payload =>
			dispatch({
				type: "SET_GRAPHQL_STATIC_DATA",
				payload
			})
	};
};

// Контейнер
const Layout = connect(
	mapStateToProps,
	mapDispatchToProps
)(LayoutComponent);

export default Layout;
