import "./navbar-toggler.scss";
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const NavbarToggler = ({ className, isToClose, onClick }) => (
	<div className={cn("navbar-toggler", className)} onClick={onClick}>
		<div
			className={`navbar-toggler__menu ${isToClose ? "active" : "not-active"}`}
		>
			<i />
			<i />
			<i />
		</div>
	</div>
);

NavbarToggler.propTypes = {
	isToClose: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired
};

NavbarToggler.defaultProps = {
	isToClose: false,
	className: ""
};

export default NavbarToggler;
