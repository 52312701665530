import React from "react";
import "./info-block.scss";

const InfoBlock = ({ showBlock, infoMessages, close }) => {
	return showBlock && infoMessages && infoMessages.is_active ? (
		<section className="full-width info-block-wrapper">
			<div className="grid-container info-block">
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-12">
						<a className="info-block__message" href={infoMessages.to}>
							{infoMessages.text}
						</a>
						<button className="close" onClick={close} />
					</div>
				</div>
			</div>
		</section>
	) : null;
};

export default InfoBlock;
