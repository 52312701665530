import React from "react";
import Button from "@components/button";
import cn from "classnames";
import Link from "gatsby-link";

import "./online-services-button.scss";

const OnlineServicesButton = ({ className, buttonClassName }) => {
	return (
		<div className={cn("online-services", className)}>
			<Link to="/payment">
				<Button
					title="Пополнить карту"
					className={cn("header__btn-check-balance", buttonClassName)}
				/>
			</Link>
		</div>
	);
};

export default OnlineServicesButton;
