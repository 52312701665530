import React from "react";
import { Accordion } from "react-accessible-accordion";
import cn from "classnames";

import "./accordion.scss";

const CustomAccordion = ({ children, className }) => (
	<Accordion
		className={cn("accordion accordion_theme_menu", className)}
		allowZeroExpanded
		allowMultipleExpanded
	>
		{children}
	</Accordion>
);

export default CustomAccordion;
