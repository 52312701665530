import React, { useState } from "react";
import Link from "gatsby-link";
import Tooltip from "rc-tooltip";

import "./nav-item.scss";

// TODO: Упростить для переиспользования в картах
const NavItem = ({ item, menuLinks, isActive, location }) => {
	const [opened, setOpened] = useState(false);

	const renderMenuLinks = () =>
		item.sections &&
		item.sections.map((itemCode, index) => {
			const menuLink = menuLinks.find(link => link.code === itemCode);
			return (
				menuLink && (
					<section key={index} className="nav-item-menu">
						{item.sections.length > 1 && (
							<div className="nav-item-menu__title">{menuLink.name}</div>
						)}
						<ul className="nav-item-menu__sections">
							{menuLink.subsections.map((subsection, index) => {
								const { name, to, link } = subsection;
								let isActive;
								if (location && location.pathname) {
									isActive = `${location.pathname}${location.search}` === to;
								}
								return (
									<li
										key={index}
										className="nav-item-menu__sections__section"
										onClick={() => setOpened(false)}
									>
										{(to || (!to && !link)) && (
											<Link
												className={`nav-item-menu__sections__section__link ${
													isActive ? "active" : ""
												}`}
												to={to || "/not-found"}
											>
												{name}
											</Link>
										)}
										{link && (
											<a
												rel="noopener noreferrer"
												href={link}
												target="_blank"
												key={index}
												className="nav-item-menu__sections__section__link"
											>
												{name}
											</a>
										)}
									</li>
								);
							})}
						</ul>
					</section>
				)
			);
		});

	return (
		<Tooltip
			trigger={["hover"]}
			overlay={<div className="nav-item">{renderMenuLinks()}</div>}
			visible={opened}
			onVisibleChange={() => setOpened(!opened)}
			placement="bottomLeft"
			destroyTooltipOnHide
			overlayClassName="offset"
			mouseEnterDelay={0.3}
			mouseLeaveDelay={0.1}
			align={{
				offset: [-10, 10]
			}}
		>
			<div className={`nav-item-link ${isActive && "active"}`}>{item.text}</div>
		</Tooltip>
	);
};

export default NavItem;
