import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const reducer = (state, action) => {
	if (action.type === `SET_DEFAULT_CITY`) {
		return {
			...state,
			city: action.payload.city
		};
	}
	if (action.type === `SET_GRAPHQL_STATIC_DATA`) {
		return {
			...state,
			staticData: {
				...state.staticData,
				...action.payload
			}
		};
	}
	return state;
};

const initialState = { city: undefined, staticData: undefined };

const initStore = () => {
	const windowGlobal = typeof window !== "undefined" && window;

	const devtools =
		process.env.NODE_ENV === "development" && windowGlobal.devToolsExtension
			? window.__REDUX_DEVTOOLS_EXTENSION__ &&
			  window.__REDUX_DEVTOOLS_EXTENSION__()
			: f => f;

	return createStore(
		reducer,
		initialState,
		compose(
			applyMiddleware(thunk),
			devtools
		)
	);
};

export default initStore;
