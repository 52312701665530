export default [
	{
		text: "Карты",
		link: "/cards/",
		sections: ["kinds", "services", "where-to-refill", "where-to-buy"]
	},
	{
		text: "О компании",
		link: "/about/",
		sections: ["aboutCompany"]
	},
	{
		text: "Контакты",
		link: "/contacts/",
		sections: ["contacts"]
	},
	{
		text: "Информация",
		link: "information/",
		sections: ["information"]
	}
];
