import React from "react";
import { AccordionItem } from "react-accessible-accordion";

import "./accordion.scss";

const CustomAccordionItem = props => {
	return (
		<AccordionItem className="accordion__item">{props.children}</AccordionItem>
	);
};

export default CustomAccordionItem;
