import React, { useState, useEffect, useCallback } from "react";
import Link from "gatsby-link";

import NavbarToggler from "./navbar-toggler";
import MainNavCitySelect from "./main-nav-city-select";
import NavItems from "./nav-items/nav-items";
import MobileMenu from "./mobile-menu";
import OnlineServicesButton from "../../online-services-button";
import InfoBlock from "./info-block";
import CovidBlock from "./covid-block";
import cn from "classnames";

import logo from "@img/logo.svg";
import shortLogo from "@img/short-logo.svg";

import "./header.scss";
import { cms as axios } from "@core/axios";

const Header = ({ location, menuLinks }) => {
	const [height, setHeight] = useState(0);
	const [el, setEl] = useState(undefined);
	const [mobileMenuIsOpen, setMobileMenuOpen] = useState(false);
	const [infoMessages, setInfoMessages] = useState(undefined);

	useEffect(() => {
		const getMessages = async () => {
			const message = await axios(`/informers?code=header_informer`);
			message &&
				message.data &&
				message.data.length &&
				setInfoMessages(message.data[0]);
		};
		getMessages();
	}, []);

	const [showBlock, setShowBlock] = useState(true);

	const close = useCallback(() => {
		setShowBlock(!showBlock);
	}, [showBlock]);

	const handleClickNavbar = useCallback(() => {
		setMobileMenuOpen(!mobileMenuIsOpen);
		if (!mobileMenuIsOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	}, [mobileMenuIsOpen]);

	useEffect(() => {
		if (el) {
			setHeight(el.clientHeight);
		}
		return () => {
			if (mobileMenuIsOpen) {
				document.body.style.overflow = "";
			}
		};
	}, [el, mobileMenuIsOpen, showBlock]);

	return (
		<>
			<div
				id="header-container"
				className={cn({ [`isOpen`]: mobileMenuIsOpen })}
				ref={ref => setEl(ref)}
			>
				<InfoBlock
					showBlock={showBlock}
					infoMessages={infoMessages}
					close={close}
				/>
				<header className="full-width header">
					<div className="grid-container">
						<div className="header__content">
							<Link className="header__logo" to="/">
								<img src={logo} alt="Логотип социальной карты" />
								<img src={shortLogo} alt="Логотип социальной карты" />
							</Link>
							{/* Основное меню */}
							<NavItems
								className="hidden-xs hidden-sm"
								menuLinks={menuLinks}
								location={location}
							/>
							<div className="header__content__left">
								{/* Контрол по выбору города */}
								<MainNavCitySelect className="hidden-xs" />
								{/* Онлайн сервисы */}
								<OnlineServicesButton className="hidden-xs hidden-sm" />
								{/* Бургер меню */}
								<NavbarToggler
									isToClose={mobileMenuIsOpen}
									className="header__navbar-toggler hidden-md"
									onClick={handleClickNavbar}
								/>
							</div>
						</div>
					</div>
				</header>
			</div>
			<MobileMenu
				height={height}
				menuLinks={menuLinks}
				onClick={handleClickNavbar}
				isOpen={mobileMenuIsOpen}
			/>
		</>
	);
};

export default Header;
