import React from "react";
import "./covid-block.scss";
import Link from "gatsby-link";
import img from "./covid-19.png";

const CovidBlock = ({ showBlock }) => {
	return showBlock ? (
		<section className="full-width covid-block-wrapper">
			<div className="grid-container covid-block">
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-12">
						<div className="covid-block__flex">
							<img src={img} alt="стоп-covid-19" />
							<div>
								<p className="covid-block__message__desktop">
									Согласно Постановлению КМ РТ от 17.11.2021г. №1100 с
									22.11.2021г. запрещается проезд пассажиров старше 18 лет в
									общественном транспорте без предъявления QR-кода о вакцинации,
									перенесенном заболевании COVID-19, мед.отводе к вакцинации.
									Воспользуйтесь сервисом проверки льготной транспортной карты
									на COVID-ограничения.
								</p>
								<p className="covid-block__message__pad">
									Воспользуйтесь сервисом проверки льготной транспортной карты
									на COVID-ограничения.
								</p>
								<Link className="covid-block__link" to="/services/stop-covid">
									Воспользуйтесь сервисом проверки льготной транспортной карты
									на COVID-ограничения.
								</Link>
							</div>
							<Link to="/services/stop-covid">
								<button className="covid-block__btn">Проверить</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	) : null;
};

export default CovidBlock;
